<template>
  <div class="mainArea">
    <div class="contentCenter">
      <h1 class="underline">JULIA NUSSER</h1>
      <h1>GOODBYE HORSEGIRL</h1>
      <h2>Sculpture, Collage, Photography</h2>
      <br>
      <h4>Opening Fr. 01.11.24, 18:00</h4>
      <h2>Artist talk: Sa. 16.11.24, 14:00</h2>
      <br>
      <h2>02.11.24 - 29.11.24</h2>
      <h2>Visits by appointment: mail@fomoartspace.ch</h2>
      <br>
      <img src="../assets/img/upcoming_11.jpg" alt="" class="contentImgBig">
      <br>
      <h4 class="textAlignCenter">He told me, "I've seen it all before</h4>
      <h4 class="textAlignCenter">I've been there, I've seen my hopes and dreams lying on the ground</h4>
      <h4 class="textAlignCenter">I've seen the sky, just begin to fall"</h4>
      <h4 class="textAlignCenter">He said, "All things pass into the night"</h4>
      <br>
      <h4 class="textAlignCenter">And I said, "Oh, no, sir</h4>
      <h4 class="textAlignCenter">I must say you're wrong</h4>
      <h4 class="textAlignCenter">I must disagree, oh, no, sir</h4>
      <h4 class="textAlignCenter">I must say you're wrong</h4>
      <h4 class="textAlignCenter">Won't you listen to me?"</h4>
      <br>
      <h4 class="textAlignCenter">Goodbye horses, I'm flying over you</h4>
      <br>
      <h4 class="textAlignCenter">Q Lazzarus - Goodbye Horses</h4>
      <br>
      <h2>Julia Nusser (b. 1991, DE) based in Zürich. Julia is revisiting situations of pain and fear seeking to overcome recurring memories of the past. They are constructing strategies of resilience with the help of aesthetics and obsessions of their teenage years, visualizing dreams and hopes for the future of belonging and loving.</h2>
      <a href="https://www.instagram.com/julianusser" target="_blank"><h5 class="underline">Julia Nusser on Instagram</h5></a>
    </div>
  </div>
</template>
<script>
  export default { 
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>